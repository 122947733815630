<template>
  <div class="container-fluid">
    <portal to="breadcrumb">
      <b-breadcrumb :items="breadcrumbs"></b-breadcrumb>
    </portal>
    <div class="px-4 pt-2 pb-5">
      <div class="bg p-3">
        <div
          class="d-flex flex-wrap mb-4 justify-content-between align-items-center"
        >
          <div class="my-1 flex-fill">
            <h4>Liste des actualités</h4>
          </div>
          <form class="d-flex my-1">
            <div class="input-group me-4">
              <input
                type="text"
                class="form-control"
                placeholder=""
                aria-label="Example text with button addon"
                aria-describedby="button-query"
              />
              <button
                type="submit"
                class="style_btn btn btn-primary"
                id="button-query"
              >
                <i class="bi bi-search"></i>
              </button>
            </div>
          </form>
          <a
            href="#"
            class="style_btn btn btn-warning"
            v-b-modal.modal-footer-sm
            >Ajouter</a
          >
          <!-- <a href="#" class="style_btn btn btn-warning">Ajouter</a> -->
        </div>
        <ActualiteEdit
          ref="actualiteEditor"
          :item="activeActualite"
          title="Actualité"
          v-on:saved="updateOrCreateActualite($event)"
        />
        <div class="bg-white p-3 border shadow">
          <div class="table-responsive">
            <b-table
              show-empty
              empty-text="Aucune donnée à afficher"
              :fields="tableFields"
              class="align-middle"
              striped
              :items="tableData"
            >
              <template #head()="data">
                <span>{{ data.label }} </span>
              </template>
              <!-- A virtual column -->
              <template #cell(index)="data">
                {{ data.index + 1 }}
              </template>

              <template #cell(contenu)="data" >
                <h6 class="mt-0 mb-3" v-html="data.contenu"></h6>
                 
              </template>

              <template #cell(actions)="data">
                <div class="d-flex justify-content-end">
                  <a
                    @click.prevent="editActualite(data.item)"
                    class="btn btn-success rounded-0 btn-sm me-1"
                    ><i class="bi bi-pencil-square"></i
                  ></a>
                  <!-- <a  class="btn btn-info rounded-0 btn-sm me-1"><i class="bi bi-info-square"></i></a> -->
                  <a
                    @click.prevent="destroyActualite(data.item)"
                    class="btn btn-danger rounded-0 btn-sm me-1"
                    ><i class="bi bi-trash"></i
                  ></a>
                </div>
              </template>
              <!-- Optional default data cell scoped slot -->
              <template #cell()="data">
                <span>{{ data.value }}</span>
              </template>
            </b-table>
          </div>
          <div
            class="d-flex flex-wrap justify-content-between align-items-center"
          >
            <p>
              Affichage de l’élément
              {{ mxPagination.page *mxPagination.rowsPerPage }} à
              {{ mxPagination.page * mxPagination.rowsPerPage }} de
              {{ mxTotalCount }} éléments
            </p>
            <b-pagination
              v-model="mxPagination.page"
              :total-rows="mxPageCount"
              :per-page="mxPagination.rowsPerPage"
              aria-controls="my-table"
            ></b-pagination>
          </div>
        </div>
      </div>
     
      <!--<div class="bg p-3">
        <div
          class="d-flex justify-content-between align-items-center mt-4"
          v-if="mxFilteredItems && mxFilteredItems.length"
        >
          <p>
            {{ pagination.numberOfElements }} éléments sur
            {{ pagination.totalElements }}
          </p>
          <nav class="">
            <ul class="pagination justify-content-end">
              <li class="page-item">
                <a
                  class="page-link"
                  v-if="pagination.pageNumber > 0"
                  @click.prevent="
                    fetchActualites({ pageNumber: pagination.pageNumber - 1 })
                  "
                  href="#"
                  aria-label="Previous"
                >
                  <span aria-hidden="true">&laquo;</span>
                </a>
              </li>
              <li
                class="page-item"
                :class="{ active: page - 1 == pagination.pageNumber }"
                v-for="(page, index) in pagination.totalPages"
                :key="index"
                @click.prevent="fetchActualites({ pageNumber: page - 1 })"
              >
                <a class="page-link" href="#">{{ page }}</a>
              </li>
              <li class="page-item">
                <a
                  class="page-link"
                  v-if="pagination.pageNumber < pagination.totalPages - 1"
                  @click.prevent="
                    fetchActualites({ pageNumber: pagination.pageNumber + 1 })
                  "
                  href="#"
                  aria-label="Next"
                >
                  <span aria-hidden="true">&raquo;</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>-->
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ActualiteEdit from "@/components/espace/admin/actualite/ActualiteEdit";
import { paginatorMixin } from "@/mixins/paginator-mixin";
// import YesOrNoBadge from "@/components/common/YesOrNoBadge.vue";


export default {
  components: {
    ActualiteEdit,
   
  },
  mixins: [paginatorMixin],
  data() {
    return {
      search: "",
      filterOptions: [],
      filters: {
        skills: [],
        availabilities: [],
        price_min: null,
        price_max: null,
        departement: null,

        _queryOptions: [
          {
            column: "departement",
            value: null,
            label: "Département",
          },
          {
            column: "commune",
            value: null,
            label: "Commune",
          },
        ],
        ordering: "",
        // _orderingOptions: {
        // }
      },
      activeActualite: {},
      breadcrumbs: [
        {
          text: "Admin",
          href: "#",
        },
        {
          text: "Paramètres",
          href: "#",
        },
        {
          text: "Actualites",
          active: true,
        },
      ],
    };
  },
  created() {
    this.mxInitializePaginator({
      queryModel: "actualite",
      search: this.search,
      fetcherMethod: "actualite/fetchActualites",
      dataGetter: "actualite/actualites",
      paginationGetter: "actualite/pagination",
      pagination: {
        sortBy: "desc",
        descending: false,
        page: 1,
        rowsPerPage: 50,
      },
      filterOptions: this.filterOptions,
      searchFields: ["search"],
    });
  },
  computed: {
    ...mapGetters({
      // actualites: "actualite/actualites",
      pagination: "actualite/pagination",
    }),
    tableFields() {
      return [
        "index",
        { key: "titre", label: "Titre" },
        "contenu",
        //  { key: "contenu", label: "contenu" },
        { key: "isPublish", label: "Publié" },
        { key: "datePublication", label: "Date de publication" },
        { key: "dateExpiration", label: "Date expiration" },
        "actions",
      ];
    },
    tableData() {
      return this.mxFilteredItems.map((item) => {
        return { ...item };
      });
    },
    sexeOptions() {
      return ["M", "F"].map((item) => ({ value: item, text: item }));
    },
  },
  methods: {
    ...mapActions({
      fetchActualites: "actualite/fetchActualites",
      createOrUpdateActualite: "actualite/createOrUpdateActualite",
      deleteActualite: "actualite/deleteActualite",
    }),
    editActualite(actualite) {
      console.log(actualite);
      this.activeActualite = { ...actualite };
      this.$refs["actualiteEditor"].show();
      // this.$router.push({ name: 'espace.admin.actualites.edit' })
    },
    async destroyActualite(actualite) {
      console.log(actualite);
      await this.$dialog.confirm({
        size: "md",
        centered: true,
        variant: "success",

        text: "Voulez-vous vraiment supprimer cet élément?",
        actions: {
          false: {
            text: "Non",
            variant: "outline-success",
          },
          true: {
            text: "Oui",
            variant: "success",
            handle: async () => {
              this.deleteActualite(actualite);
            },
          },
        },
      });
      // const ok = await this.$dialog.confirm({ text: 'Do you really want to exit?'})
      // if (ok) {
      // }
    },
    updateOrCreateActualite(actualite) {
      console.log(actualite);
      // if (actualite.ispublish == true){
      //   actualite.is_publish = 1
      // }
      // else{
      //   actualite.is_publish = 0
      // }
      this.createOrUpdateActualite(actualite).then((data) => {
        console.log(data);
        this.$refs["actualiteEditor"].show();
        this.$toast.success("Opération réussie!", {
          position: "bottom-right",
          duration: 5000,
        });
      });
    },
  },
};
</script>

<style></style>
