<template>
  <div>
    <slot name="action">
      <!-- <a href="#" class="style_btn btn btn-warning" v-b-modal.modal-footer-sm
        >Ajouter</a
      > -->
      <!-- <b-button variant="style_btn btn btn-warning" v-b-modal.modal-footer-sm>Créer</b-button> -->
    </slot>
    <b-modal
      id="modal-footer-sm"
      ref="modal"
      size="lg"
      hide-footer
      :title="title"
      modal-ok="Valider"
      modal-cancel="Annuler"
    >
      <template #modal-header>
        <h5 class="modal-title" id="staticBackdropLabel">ACTUALITE</h5>
        <button
          type="button"
          class="style_btn btn-close"
          data-bs-dismiss="modal"
          @click.prevent="cancel"
          aria-label="Close"
        ></button>
      </template>
      <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
        <form ref="form" method="post" @submit.prevent="handleSubmit()">
          <!-- <br><br><br><br> -->
          <!-- <div class="row">
            <div class="col-sm-12">
              <ValidationProvider rules="required" v-slot="{ errors }">
                <div class="form-floating mb-3">
                  <input :value="editableItem.name" @input="handleInput" type="text" class="form-control" id="name" name="name" placeholder="Libellé">
                  <label for="name">Libellé</label>
                  <span v-for="(err, index) in errors" :key="index" class="text-danger">{{ err }}</span>
                </div>
              </ValidationProvider>
            </div>
            (e) => fileLoad(e, editableItem)
          </div> -->
          <div class="bg-white p-3 border shadow">
            <div class="row justify-content-center pb-5">
              <div class="col-md-9">
                <form>
                  <div class="row">
                    <div class="col-12">
                      <label for="tags" class="form-label">Type d'actualité</label>

                      
                      <c-select
                        id="typeActualite"
                        name="typeActualite"
                        option-label="label"
                        option-value="code"
                        :emit-object="false"
                        v-model="editableItem.typeActualite"
                        :options="typeActualiteOptions"
                        class="form-select"
                      >
                      </c-select>
                    </div>

                    <div class="col-md-12">
                      <label class="form-label" for="customFile">Image</label>
                      <!-- <input
                      type="file"
                      @change="(e) => fileLoad(e, editableItem)"
                      class="form-control"
                      accept="image/jpeg, image/png"
                    /> -->
                      <FilePondUploader
                        ref="fileUploader"
                        class="form-control col-12 md:col-12"
                        @save="fileSaved($event)"
                        :autoResize="true"
                        rows="8"
                      />
                    </div>

                    <div class="col-md-12">
                      <label for="question" class="form-label">Titre</label>

                      <input
                        v-model="editableItem.titre"
                        type="text"
                        class="form-control"
                        id="question"
                      />
                    </div>

                    <div class="col-12">
                      <label for="reponse" class="form-label">Contenu</label>
                      <vue-editor
                        id="reponse"
                        v-model="editableItem.contenu"
                      ></vue-editor>

                      <!-- <textarea
                      
                      type="text"
                      
                      id="reponse"
                      rows="5"
                      placeholder=""
                    /> -->
                    </div>
                    <div class="col-12">
                      <label for="tags" class="form-label">Tags</label>

                      <input
                        type="text"
                        v-model="editableItem.tags"
                        class="form-control"
                        id="tags"
                      />
                    </div>

                    <div class="row">
                      <div class="col-12">
                        <label for="datPub" class="form-label"
                          >Date de publication</label
                        >
                      </div>
                      <br />
                      <div>
                        <p-calendar
                          class="field col-12"
                          id="datPub"
                          placeholder="Date de publication"
                          v-model="editableItem.datePublication"
                          locale="fr"
                          :showIcon="true"
                          :manualInput="false"
                        />
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-12">
                        <label class="form-label">Date d'expiration</label>
                      </div>
                      <br />
                      <div>
                        <p-calendar
                          class="field col-12"
                          placeholder="Date d'expiration"
                          v-model="editableItem.dateExpiration"
                          locale="fr"
                          :showIcon="true"
                          :manualInput="false"
                        />
                      </div>
                    </div>
                    <br />

                    <div class="col-12">
                      <div class="form-check">
                        <input
                          class="form-check-input"
                          type="checkbox"
                          id="gridCheck"
                          v-model="editableItem.isPublished"
                        />
                        <label class="form-check-label" for="gridCheck"
                          >Publié
                        </label>
                      </div>
                    </div>
                    <br />
                    <div class="col-12">
                      <button @click.prevent="ok()" class="btn btn-primary">
                        Enregistrer
                      </button>
                    </div>
                    <br />
                  </div>
                </form>
              </div>
            </div>
          </div>
          <!-- <div class="d-grid gap-2">
            <button
              @click.prevent="save"
              type="submit"
              class="style_btn btn btn-success btn-lg"
            >
              Enrégistrer
            </button>
          </div> -->
        </form>
      </ValidationObserver>
      <!-- <div class="container mb-5">
    <div class="d-flex flex-wrap mb-4 justify-content-between align-items-center">
    <div class="my-1 flex-fill">
      <h4>Edition de mes qualification</h4>
      <small>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod
    tempor incididunt ut labore et dolore magna aliqua.</small>
    </div>
    <form class="d-flex my-1">
              <a href="#" class="style_btn btn btn-success">Sauvegarde</a>
              <a href="#" class="style_btn btn btn-warning">Ajouter</a>
      </form>
  </div>
 
</div> -->
    </b-modal>
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import { VueEditor } from "vue2-editor";
import FilePondUploader from "../../../../components/uploader/FilePondUploaderOneFile.vue";

export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    VueEditor,
    FilePondUploader,
  },
  data() {
    return {
      file: null,
      editableItem: {},
      imag: "",
    };
  },
  watch: {
    item() {
      console.log(this.item);
      this.editableItem = { ...this.item };
    },
  },
  computed: {
    ...mapGetters({
      typeActualiteOptions: "setting/typeActualiteOptions"
    }),
    options() {
      return this.structures.map((item) => ({
        value: item,
        text: item.libelle,
      }));
    },
  },
  methods: {
    fileSaved(files) {
      let imageUrl = this.editableItem.imageUrl;
      imageUrl = this.$refs.fileUploader.getFilesUrls();
      console.log(files);
      this.editableItem = {
        ...this.editableItem,
        imageUrl,
      };
      console.log(this.editableItem);
    },
    show() {
      this.$refs["modal"].show();
    },
    hide() {
      this.$refs["modal"].hide();
    },
    ok() {
      this.$emit("saved", this.editableItem);
      console.log("je suis dans edit" + this.editableItem);
      this.hide();
      this.editableItem = {};
    },
    cancel() {
      this.hide();
      this.$emit("canceled");
    },
    handleInput(e) {
      this.editableItem[e.target.name] = e.target.value;
      this.$emit("input", this.editableItem);
    },
    async isCompleted() {
      const valid = await this.$refs.observer.validate();
      return valid;
    },
    // async save() {
    //   if (!(await this.isCompleted())) return;
    //   this.$emit("save", this.editableItem);
    //   this.hide();
    // },

    fileLoad(value, object) {
      let reader = new FileReader(),
        files = value.target.files || value.dataTransfer.files;
      if (!files.length) return;
      reader.readAsDataURL(files[0]);

      reader.onload = (e) => {
        Vue.set(object, "image", e.target.result);
      };

      console.log(this.editableItem);
    },

    fileLoad2(value) {
      let reader = new FileReader(),
        files = value.target.files || value.dataTransfer.files;
      if (!files.length) return;
      reader.readAsDataURL(files[0]);

      console.log(this.editableItem);
    },
  },
};
</script>
